import "./colors.css"
export default function Proj() {
  return (
    <div className="text-light" style={{ marginLeft: "", padding: "10px" }}>
      <hr></hr>
      <h5 className="text-primary hh5">Portfolio</h5>
      <div className="container">
        <div className="row">
          <div className="col-md-6 col-12">
            <div style={{ backgroundColor: "darkgray", minHeight: "450px" }}>
              <div
                style={{
                  backgroundColor: "gray",
                  height: "250px",
                  marginTop: "20px",
                  backgroundImage: "url('Screenshot 2024-04-21 065119.png')",
                  backgroundSize: "cover",
                }}
              ></div>
              <p style={{ fontSize: "15px", padding: "30px" }}>
                <b className="hhh" style={{ fontSize: "19px" }}>
                  Criminal Document System — Web application
                </b>
                <br></br>
                and Bootstrap framework technology stack. Aims to <br></br>{" "}
                provide a document management system for the local police
                department, and a criminal profile search engine.
                <a href="https://github.com/abedAbulail/Criminal_Document_System">
                  Click to see the code
                </a>
              </p>
            </div>
          </div>
          <div className="col-md-6 col-12">
            <div style={{ backgroundColor: "darkgray", minHeight: "450px" }}>
              <div
                style={{
                  backgroundColor: "gray",
                  height: "250px",
                  marginTop: "20px",
                  backgroundImage: "url('Screenshot 2024-04-21 064043.png')",
                  backgroundSize: "cover",
                }}
              ></div>
              <p style={{ fontSize: "15px", padding: "30px" }}>
                <b className="hhh" style={{ fontSize: "19px" }}>
                  Student Collaboration Application
                </b>
                <br></br>A startup project utilizing the microservices
                architecture. A web application to help students collaborate and
                be active in diƦerent communities. It is an ongoing project, and
                is managed and developed by a team of developers.
                <a href="https://github.com/abedAbulail/student_collaboration_application">
                  Click to see the code
                </a>
              </p>
            </div>
          </div>
          <div className="col-md-6 col-12">
            <div style={{ backgroundColor: "darkgray", minHeight: "450px" }}>
              <div
                style={{
                  backgroundColor: "gray",
                  height: "250px",
                  marginTop: "20px",
                  backgroundImage: "url('Screenshot 2024-04-21 061957.png')",
                  backgroundSize: "cover",
                }}
              ></div>
              <p style={{ fontSize: "15px", padding: "30px" }}>
                <b className="hhh" style={{ fontSize: "19px" }}>
                  Snake Game (JavaScript Only)
                </b>
                <br></br>
                Snake game for entertainment. You must eat the food in the game
                to get the highest possible number of points
                <a href="https://github.com/abedAbulail/Snake_js_Game">
                  Click to see the code
                </a>
              </p>
            </div>
          </div>
          <div className="col-md-6 col-12">
            <div style={{ backgroundColor: "darkgray", minHeight: "450px" }}>
              <div
                style={{
                  backgroundColor: "gray",
                  height: "250px",
                  marginTop: "20px",
                  backgroundImage: "url('Screenshot 2024-04-21 064914.png')",
                  backgroundSize: "cover",
                }}
              ></div>
              <p style={{ fontSize: "15px", padding: "30px" }}>
                <b className="hhh" style={{ fontSize: "19px" }}>
                  Web design demo
                </b>
                <br></br>
                Simple design for a Web-Site that has a cards design, header
                with form and Footer.
                <a href="https://github.com/abedAbulail/web_design_demo">
                  Click to see the code
                </a>
              </p>
            </div>
          </div>
          <div className="col-md-6 col-12">
            <div style={{ backgroundColor: "darkgray", minHeight: "450px" }}>
              <div
                style={{
                  backgroundColor: "gray",
                  height: "250px",
                  marginTop: "20px",
                  backgroundImage: "url('Screenshot 2024-04-21 064327.png')",
                  backgroundSize: "cover",
                }}
              ></div>
              <p style={{ fontSize: "15px", padding: "30px" }}>
                <b className="hhh" style={{ fontSize: "19px" }}>
                  News API (React Web-application)
                </b>
                <br></br>
                An Example of news API for developing a News webSite<br></br>
                <a href="https://github.com/abedAbulail/News_API">
                  Click to see the code
                </a>
              </p>
            </div>
          </div>
          <div className="col-md-6 col-12">
            <div style={{ backgroundColor: "darkgray", minHeight: "450px" }}>
              <div
                style={{
                  backgroundColor: "gray",
                  height: "250px",
                  marginTop: "20px",
                  backgroundImage: "url('Screenshot 2024-04-21 064059.png')",
                  backgroundSize: "cover",
                }}
              ></div>
              <p style={{ fontSize: "15px", padding: "30px" }}>
                <b className="hhh" style={{ fontSize: "19px" }}>
                  Vikings Cast page
                </b>
                <br></br>
                Example of a movie info page, actors and all the details.
                <br></br>
                <a href="https://github.com/abedAbulail/Movie_info_page">
                  Click to see the code
                </a>
              </p>
            </div>
          </div>
          <div className="col-md-6 col-12">
            <div style={{ backgroundColor: "darkgray", minHeight: "450px" }}>
              <div
                style={{
                  backgroundColor: "gray",
                  height: "250px",
                  marginTop: "20px",
                  backgroundImage: "url('Screenshot 2024-04-21 064014.png')",
                  backgroundSize: "cover",
                }}
              ></div>
              <p style={{ fontSize: "15px", padding: "30px" }}>
                <b className="hhh" style={{ fontSize: "19px" }}>
                  Simple design (landing page)
                </b>
                <br></br>
                Example of a simple design page, landing page Beautiful and easy
                to read, it can be used to showcase your projects.
                <a href="https://github.com/abedAbulail/Simple_landing_page_design">
                  Click to see the code
                </a>
              </p>
            </div>
          </div>
          <div className="col-md-6 col-12">
            <div style={{ backgroundColor: "darkgray", minHeight: "450px" }}>
              <div
                style={{
                  backgroundColor: "gray",
                  height: "250px",
                  marginTop: "20px",
                  backgroundImage: "url('Screenshot 2024-04-21 064125.png')",
                  backgroundSize: "cover",
                }}
              ></div>
              <p style={{ fontSize: "15px", padding: "30px" }}>
                <b className="hhh" style={{ fontSize: "19px" }}>
                  Simple Olives Markit design (landing page)
                </b>
                <br></br>
                Example of a simple olives design page, landing page Beautiful
                and easy to read.
                <a href="https://github.com/abedAbulail/Olives_Markit_landing_page">
                  Click to see the code
                </a>
              </p>
            </div>
          </div>
          <div className="col-md-6 col-12">
            <div style={{ backgroundColor: "darkgray", minHeight: "450px" }}>
              <div
                style={{
                  backgroundColor: "gray",
                  height: "250px",
                  marginTop: "20px",
                  backgroundImage: "url('Screenshot 2024-04-21 064246.png')",
                  backgroundSize: "cover",
                }}
              ></div>
              <p style={{ fontSize: "15px", padding: "30px" }}>
                <b className="hhh" style={{ fontSize: "19px" }}>
                  Morning Doaa
                </b>
                <br></br>
                Javascript simple Example, It makes you know how many times to
                say Doaa.
                <a href="https://github.com/abedAbulail/Morning_Doaa">
                  Click to see the code
                </a>
              </p>
            </div>
          </div>
          <div className="col-md-6 col-12">
            <div style={{ backgroundColor: "darkgray", minHeight: "450px" }}>
              <div
                style={{
                  backgroundColor: "gray",
                  height: "250px",
                  marginTop: "20px",
                  backgroundImage: "url('Screenshot 2024-04-21 064114.png')",
                  backgroundSize: "cover",
                }}
              ></div>
              <p style={{ fontSize: "15px", padding: "30px" }}>
                <b className="hhh" style={{ fontSize: "19px" }}>
                  Bootstrap design
                </b>
                <br></br>
                landing page design using Bootstrap, Example of a business page
                and learn about the company's work
                <a href="https://github.com/abedAbulail/Bootstrap_design">
                  Click to see the code
                </a>
              </p>
            </div>
          </div>
          <div className="col-md-6 col-12">
            <div style={{ backgroundColor: "darkgray", minHeight: "450px" }}>
              <div
                style={{
                  backgroundColor: "gray",
                  height: "250px",
                  marginTop: "20px",
                  backgroundImage: "url('Screenshot 2024-04-21 211447.png')",
                  backgroundSize: "cover",
                }}
              ></div>
              <p style={{ fontSize: "15px", padding: "30px" }}>
                <b className="hhh" style={{ fontSize: "19px" }}>
                  Iphone web page design
                </b>
                <br></br>A page that displays information about iPhone
                smartphones with a beautiful design for displaying images
                <a href="https://github.com/abedAbulail/Iphone_info_page">
                  Click to see the code
                </a>
              </p>
            </div>
          </div>
        </div>
      </div>
      {/* <p >
       
        <br></br>A full stack web application utilizing PHP, HTML, CSS, JQuery
        
      </p>
      <p style={{ fontSize: "17px", padding: "30px" }}>
        <b className="hhh" style={{ fontSize: "22px" }}>
          Student Collaboration Application — Web and mobile applications
        </b>
        <br></br>A startup project utilizing the microservices architecture. A
        web application to help students collaborate and be <br></br> active in
        diƦerent communities. It is an ongoing project, and is managed and
        developed by a team of developers.
      </p> */}
    </div>
  );
}
