import MyCV from "./MyCV";
import { CSSTransition } from "react-transition-group";
import MyProj from "./MYProj";
import "./fade.css";

export default function All({ val }) {
  
  return (
    <div className="container bg-dark shadow">
      <CSSTransition
        in={val === "My CV"}
        timeout={500}
        classNames="fade"
        unmountOnExit
        appear
      >
        <MyCV />
      </CSSTransition>
      <CSSTransition
        in={val === "My Projects"}
        timeout={500}
        classNames="fade"
        unmountOnExit
        appear
      >
        <MyProj />
      </CSSTransition>
    </div>
  );
}
