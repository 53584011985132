
import { useState } from "react";

import All from "./all";

function App() {
  const [val, setval] = useState("My CV");

  return (
    <div>
      <div className="bg-secondary ">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-11 col-md-5 col-lg-3">
              <button
                style={{
                  borderBottom: "3px white solid",
                  borderRadius: "0px",
                  padding: "1px",
                  margin: "10px",
                }}
                onClick={() => {
                  setval("My CV");
                }}
                className="btn text-light text-center"
              >
                <b>My CV</b>
              </button>
              <button
                style={{
                  borderBottom: "3px solid",
                  borderTop:"0px solid",
                  borderRadius: "0px",
                  padding: "1px",
                  margin: "10px",
                }}
                onClick={() => {
                  setval("My Projects");
                }}
                className="btn text-center"
              >
                <b>My Portfolio</b>
              </button>
            </div>
          </div>
        </div>
      </div>
      <All val={val}></All>
    </div>
  );
}

export default App;
