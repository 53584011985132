import React from "react";
import "./colors.css";

export default function Side() {
  return (
    <div className="col col-md-5 bg-dark">
      <div style={{ width: "90%", color: "white", padding: "5%" }}>
        <div
          style={{
            width: "200px",
            height: "200px",
            borderRadius: "50%",
            margin: "30px auto",
            backgroundImage: "url('Screenshot 2024-04-22 152244.png')",

            backgroundSize: "cover", // Ensure the image covers the div
            backgroundPosition: "center", // Center the background image
          }}
        ></div>
        <h4 className="text-center m-3">Abed-Alrahman Abulail</h4>
        <p className="text-center">"NNU Computer Science Student"</p>
        <hr
          style={{ width: "50%", marginLeft: "auto", marginRight: "auto" }}
        ></hr>
        <h5 className="text-primary hh5">
          <b> BIOGRAPHY</b>
        </h5>
        <p>
          My name is Abdulrahman Abulail, a computer science student at An-Najah
        
          National University (almost graduating). As an aspiring software
          
          developer, I am eager to apply my skills in a dynamic professional
         
          environment. My goal is to gain valuable experience, enhance my
          expertise, and contribute meaningfully to a team, fostering both
          
          personal and career growth in the business sector.
        </p>
        <h5 className="text-primary hh5">
          <b>EDUCATION</b>
        </h5>
        <p>
          <b className=" hhh">High School</b>, Burqa secondary school September
          2020 - June 2021 <br></br>
          score: 83.7
        </p>
        <p>
          <b className="hhh">An-Najah National Univ.</b>, Nablus — Bachelor’s
          September 2021 - January <br></br>
          2025 (expected) Majoring in Computer Science
        </p>

        <h5 className="text-primary hh5">
          <b>TRAINING</b>
        </h5>
        <h5 className="hhh">Full Stack development</h5>
        <p>2021 - Udemy, pluralsight and self learning</p>
        <h5 className="hhh">Flutter, Front end development</h5>
        <p>2022 - 2023 - Freelancing, and self learning</p>
        <h5 className="text-primary hh5">
          <b>CAREER OBJECTIVES</b>
        </h5>
        <p>
          As a Computer Science student, I aim to gain valuable experience at
          top 
          tech companies and become an active freelancer at the same time. My
          goal is to improve my technical skills in areas like web and mobile{" "}
          
          development.
        </p>
        <p>
          I want to join a dynamic team where I can use what I've learned and{" "}
         
          contribute to exciting projects. My aim is to become a well-rounded{" "}
         
          professional in computer science, have my own business managing tech{" "}
          
          projects and make a diƦerence in the industry.
        </p>
      </div>
    </div>
  );
}
