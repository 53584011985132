import Info from "./info";
import Side from "./sidepar";
export default function MyCV() {
  return (
    <div className="row justify-content-between m-5">
      <Side />
      <Info />
    </div>
  );
}
