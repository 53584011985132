import "./all.css"
export default function Info() {
  return (
    <div className="col col-md-5">
      <div
        style={{
          width: "100%",
          color: "white",

          marginTop: "100px",
        }}
      >
        <hr></hr>
        <h5 className="headInfo">
          Burqa, Eastern St. <br></br>
          Nablus, West Bank <br></br>
          +972 59-4435028 <br></br>
          abulailabood7@gmail.com
        </h5>
        <a href="https://www.facebook.com/obada.abulail.35">
          <img src="1233013_facebook_icon.png" width={50} alt=""></img>
        </a>
        <a href="https://www.messenger.com/t/100004104221619">
          <img
            src="4373130_facebook_logo_logos_messenger_icon.png"
            width={60}
            alt=""
          ></img>
        </a>
        <a href="https://www.instagram.com/abood_abulail/?hl=ar">
          <img
            src="6636566_instagram_social media_social network_icon.png"
            width={50}
            alt=""
          ></img>
        </a>
        <a href="https://www.linkedin.com/in/abdulrahman-abulail-5192b8300/">
          <img
            src="3225190_app_linkedin_logo_media_popular_icon.png"
            width={50}
            alt=""
          ></img>
        </a>
        <h5 className="text-primary hh5">
          <b>SKILLS</b>
        </h5>
        <p>
          Problem Solving <br></br>
          Works in a team<br></br>
          Object Oriented <br></br>
          Programming<br></br>
          PHP<br></br>
          HTML/CSS/Javascript<br></br>
          Java<br></br>
          C++<br></br>
          SQL/Mysql DB<br></br>
          Android Development<br></br>
          Version Control / GIT<br></br>
          Flutter<br></br>
          ReactJS
        </p>
        <h5 className="text-primary hh5">
          <b>SOCIAL ACTIVITIES</b>
        </h5>
        <p>
          Year-long volunteering,<br></br>
          emphasizing skill<br></br>
          development and teamwork
        </p>
        <p>
          Contributed to multiple <br></br>
          debating contests in my local<br></br>
          community.
        </p>
        <p>
          Active volunteer for <br></br>
          An-Najah University<br></br>
          Volunteering Unit<br></br>
        </p>
        <h5 className="text-primary hh5">
          <b>LANGUAGES</b>
        </h5>
        <p>
          Arabic, English (good at <br></br>
          writing and listening)
        </p>

        <h5 className="text-primary hh5">
          <b>HOBBIES</b>
        </h5>
        <p>
          Doing sports and listening to <br></br>
          music
        </p>
      </div>
    </div>
  );
}
